var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"70%"},scopedSlots:_vm._u([{key:"default",fn:function({ isActive }){return [_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Assign Petani")])]),_c('v-card-text',{staticClass:"farmer-assign-wrapper mt-3"},[_c('ValidationObserver',{ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('v-btn',{attrs:{"variant":"success"},on:{"click":_vm.addRow}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_vm._v("Tambah Tahun Program")])],1)],1),_vm._l((_vm.farmers),function(f,i){return _c('v-col',{attrs:{"lg":"12"}},[_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                        label: 'Nama FF',
                        type: 'select',
                        api: 'GetFFAllWeb_new',
                        validation: ['required'],
                        setter: 'key1',
                        option: {
                          default_label: f.field_facilitators_name,
                          list_pointer: {
                            code: 'ff_no',
                            label: 'name',
                            display: ['name'],
                          },
                        },
                      },"disabled":f.id ? true : false},model:{value:(f.key1),callback:function ($$v) {_vm.$set(f, "key1", $$v)},expression:"f.key1"}})],1),(!_vm.$_sys.isAllowed('farmer-unassign-it-create'))?_c('v-col',{attrs:{"lg":"5"}},[_c('geko-input',{attrs:{"item":{
                        label: 'Tahun Program',
                        type: 'select',
                        validation: ['required'],
                        option: {
                          default_label: f.program_year,
                          default_options: _vm.$_config.programYear.options.map(
                            (x) => {
                              return {
                                label: x,
                                code: x,
                              };
                            }
                          ),
                          list_pointer: {
                            label: 'label',
                            code: 'code',
                            display: ['label'],
                          },
                        },
                      },"disabled":f.id ? true : false},model:{value:(f.program_year),callback:function ($$v) {_vm.$set(f, "program_year", $$v)},expression:"f.program_year"}})],1):_c('v-col',{attrs:{"lg":"5"}},[_c('geko-input',{attrs:{"item":{
                        label: 'Tahun Program',
                        type: 'text',
                        validation: ['required'],
                      }},model:{value:(f.program_year),callback:function ($$v) {_vm.$set(f, "program_year", $$v)},expression:"f.program_year"}})],1),_c('v-col',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"center","transform":"translateY(10%)"},attrs:{"lg":"1"},on:{"click":function($event){return _vm.onDelete(f, i)}}},[_c('v-btn',{attrs:{"variant":"danger","disabled":_vm.loading || f.program_year == '2024'}},[_c('v-icon',[_vm._v("mdi-delete-empty")])],1)],1)],1)],1)}),_c('v-col',{staticClass:"d-flex flex-row justify-content-center",attrs:{"lg":"12"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"variant":"warning","type":"submit","disabled":_vm.loading}},[_c('v-icon',[_vm._v("mdi-pencil-outline")]),_c('span',[_vm._v("Perbarui Data Petani")])],1)],1)],2)],1)]}}],null,true)})],1)],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})
}
var staticRenderFns = []

export { render, staticRenderFns }